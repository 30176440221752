.leaveExamModal :global(.modal-body) {
  padding: 0 20px;
}

.leaveExamModal :global(.modal-header) {
  padding: 20px;
}

.leaveExamModal :global(.modal-body) {
  padding: 0 20px;
}

.leaveExamModal :global(.modal-title) {
  font-family: 'museo-slab', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.leaveExamModal :global .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
