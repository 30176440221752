.manualPage {
  padding-bottom: 50px;
}

.manualPageTitle {
  margin: 50px 0 26px;
}

.pageText,
.pageTextBold {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.pageSubtitle {
  display: block;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

.pageTextBold {
  font-weight: bold;
}

.manualPageContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.manualPageContentIcon {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 44px 0 28px;
}

.manualPageContentIcon div {
  margin-right: 20px;
}

.rulesList {
  display: flex;
  margin-right: 20px;
  width: 100%;
}
