.tableContainer :global(th),
.tableContainer :global(td) {
  text-align: center;
}

.loaderWrapper {
  width: 100%;
  min-width: 100%;
  position: absolute;
  min-height: 180px;
}

.backButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
}

.backButtonWrapper :global(.btn) {
  color: var(--text-color);
}

.noResultContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
}

.noResultContainer p {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  color: var(--neutral-dark-blue);
}

.backButton {
  font-family: 'retro', serif;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.backButton:hover {
  color: var(--text-color);
}

.backButton svg {
  width: 25px;
  height: 25px;
}

.backButton svg polygon {
  fill: var(--blackColor);
}
