.TCSOffersPage {
  padding-top: 60px;
  /*padding-bottom: 80px;*/
}

.TSCOffersText {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  margin: 0;
}

.itemTitle {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  color: var(--text-color);
}

.subtitle {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0 0 0;
  color: var(--text-color);
}

.listContainer {
  background-color: var(--lavender-grey);
  margin-bottom: -57px;
  padding-top: 35px;
  padding-bottom: 80px;
}

.bannerWrapper div {
  width: auto;
  height: auto;
}

.bannerWrapper img {
  width: 100%;
}

.itemsWrapper {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.itemTextContainer {
  padding-left: 25px;
}

.colButtonWrapper {
  text-align: center;
}

.underTitle {
  margin-top: 20px;
  font-weight: bold;
  font-size: 22px;
  color: var(--text-color);
}

.underContainer {
  margin-top: 60px;
}

.underText {
  font-weight: 500;
  font-size: 22px;
}

.container {
  padding-bottom: 45px;
}

@media (max-width: 1199px) {
  .underContainer img {
    width: 132px;
  }
}

@media (max-width: 991px) {
  .bannerWrapper div {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .bannerWrapper img {
    width: 350px;
  }

  .itemsImageContainer {
    text-align: center;
    margin: 20px 0;
  }

  .itemsImageContainer img {
    width: 150px;
  }

  .colButtonWrapper a {
    margin: 20px 0;
  }

  .itemTextContainer .itemTitle {
    margin-top: 20px;
  }

  .bottomLogoContainer {
    text-align: center;
    margin-bottom: 25px;
  }

  .listContainer {
    margin-bottom: -20px;
  }
}

@media (max-width: 450px) {
  .bannerWrapper img {
    width: 290px;
  }
}
