.root {
  font-family: 'Hind', sans-serif;
  font-style: normal;
  font-weight: normal;
}

.header {
  padding: 20px;
}

.body {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
}

.footer :global(.btn) {
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.title {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
  margin: 0;
}

.text {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  margin-bottom: 8px;
  text-align: start;
}

.text .linkText {
  color: var(--button-default-focused);
}

.passwordContainer {
  text-align: center;
}

.passwordContainer .passwordInput {
  display: inline;
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: var(--neutral-dark-blue);
  border: 0;
  background-color: transparent;
  max-width: 100%;
}

.passwordContainer .copyHint {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  transition: 0.3s;
  opacity: 0;
}

.passwordContainer .copyHint.copyHintShow {
  opacity: 1;
}

.body .copyButton {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: var(--modal-footer-btn-color);
}
