.questionPage :global(.rulesGroupItem h1) {
  margin-bottom: 20px;
}

.questionTopicBg {
  background-color: var(--additional-gray-blue-20);
}

.mobileView {
  display: none;
}

.gameDetails {
  background-color: var(--additional-gray-blue-20);
  margin-top: 10px;
  padding: 30px 0 30px;
  margin-bottom: -60px;
}
