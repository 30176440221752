.buttonContainer {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  min-width: unset;
  min-height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContainer img {
  width: 50px;
}

.buttonContainer span {
  font-weight: 900;
  font-size: 24px;
}
