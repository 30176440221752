.label {
  display: flex;
  align-items: flex-start;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-dark-blue-70);
  margin-bottom: 0;
}

.inputField {
  display: none;
}

.labelBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(119, 140, 163, 0.4);
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 4px;
  padding: 0;
  margin-right: 16px;
  transition: all ease-in 0.3s;
  background-color: var(--whiteColor);
}

:global(.dark-theme) .labelBox {
  border-color: var(--not-changine-white);
}

.checkboxField {
  display: flex;
  margin-bottom: 6px;
  padding: 6px;
  border-radius: 6px;
  min-height: 60px;
}

.labelBox.checked {
  border-color: var(--neutral-dark-blue-70);
}

.labelBox.correct {
  border-color: var(--functional-success-bold);
  border-width: 2px;
}

.labelBox.incorrect {
  border-color: var(--functional-warning-bold);
  border-width: 2px;
}

.labelBox.checked,
.labelBox.correct,
.labelBox.incorrect {
  position: relative;
}

span.checked:before,
span.correct:before,
span.incorrect:before {
  position: absolute;
  font-family: 'icomoon';
  font-size: 25px;
  font-weight: 800;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

span.checked:before {
  content: '\e902';
  color: var(--theme-primary);
}

span.correct:before {
  color: var(--functional-success-bold);
}

span.incorrect:before {
  color: var(--functional-warning-bold);
}

p.correct {
  background-color: var(--functional-success-10);
}

p.incorrect {
  background-color: var(--whiteColor);
}

p.checked {
  background-color: var(--functional-error-10);
}

p.checked .labelBox.checked:before {
  content: '';
}

p.checked .labelBox.checked {
  border-color: var(--functional-warning);
}

.showCorrect {
  background-color: var(--functional-success-10);
}
