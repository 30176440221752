.root {
  padding: 60px 0 30px;
}

.subtitle {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
  margin: 20px 0 28px;
}

.text {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.links {
  margin-top: 20px;
}
