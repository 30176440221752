.homeSlider {
  margin-bottom: 36px;
  min-height: 200px;
  max-width: 300px;
}

.homeSlider :global .carousel-indicators {
  right: auto;
  margin-left: 0;
  margin-right: 0;
}

.homeSlider :global .carousel-control-prev,
.homeSlider :global .carousel-control-next {
  display: none;
}

.sliderTitle {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-gray-blue);
  margin-bottom: 4px;
}

.homeSlider .sliderText {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue);
  margin: 12px 0 0;
}

.homeSlider :global .carousel-indicators > li {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--neutral-gray);
  opacity: 1;
  transition: ease-in-out 0.4s;
}

.homeSlider :global .carousel-indicators > li:not(:first-child) {
  margin-left: 8px;
}

.homeSlider :global .carousel-indicators > li.active,
.homeSlider :global .carousel-indicators > li:hover {
  background-color: var(--theme-primary);
}

.homeSlider :global .carousel-indicators > li:hover {
  cursor: pointer;
}

.langList {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 1199px) {
  .homeSlider {
    margin: 0 auto 5px;
  }
}

@media (max-width: 991px) {
  .homeSlider {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobileSlider {
    text-align: left;
  }
}
