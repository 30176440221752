.howItWorksContainer {
  padding-top: 60px;
  padding-bottom: 80px;
}

.subtitle {
  font-size: 24px;
  font-weight: bold;
  margin: 55px 0 30px 0;
  color: var(--text-color);
}

.howItWoksText {
  font-weight: 500;
  font-size: 17px;
  white-space: pre-line;
  color: var(--text-color);
}

.textWithLineHeight {
  font-weight: 500;
  font-size: 17px;
  white-space: pre-line;
  color: var(--text-color);
  /*line-height: 40px;*/
}

.disabled {
  pointer-events: none;
}

.loginBtn {
  width: 100%;
  pointer-events: none;
}

.sectionMargin {
  margin-top: 20px;
}

.tcsNumberContainer {
  background-color: var(--lavender-grey);
  margin-top: 20px;
  padding: 20px 40px;
}

.tcsNumberTitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color);
}

ul.tcsNumberList li::before {
  width: 10px;
  height: 10px;
  background-color: var(--text-color);
  border-radius: 100%;
  margin-right: 30px;
  position: absolute;
  top: 8px;
  left: 0;
  content: ' ';
}

.phoneLink {
  color: var(--text-color);
  /*margin: 0 5px;*/
  text-decoration: underline;
}

.phoneLink:hover {
  color: var(--text-color);
}

ul.tcsNumberList {
  padding-left: 20px;
}

ul.tcsNumberList li {
  position: relative;
  /*display: flex;*/
  /*align-items: center;*/
  color: var(--text-color);
  padding-left: 50px;
}

/*offersCard*/

.offersCardContainer {
  background-color: var(--neutral-light-gray);
}

.offersCardContainer :global(.linkBtn),
.offersCardContainer :global(.btn-primary) {
  display: none;
}

.offersCardWrapper div div {
  justify-content: flex-start;
}

.offersCardWrapper > div {
  margin: 0;
  padding: 0;
}

.offersCardWrapper :global(.card) {
  margin: 5px;
  pointer-events: none;
  max-width: calc(20% - 10px);
}

.offersCardWrapper :global(.card-body) {
  padding: 10px;
}

.offersCardWrapper :global(.card-footer) {
  margin-top: 5px;
  padding-bottom: 10px;
}

.offersCardWrapper :global(h3) {
  font-size: 20px;
}

.offersCardWrapper :global(.card-title) {
  margin-bottom: 5px;
}

.offersCardWrapper :global(.card-text) {
  font-size: 13px;
}

.offersCardWrapper :global(.header-text) {
  font-size: 18px;
}

/*cardContent*/

.cardCategoriesContainer {
  padding: 72px 0 88px;
  background-color: var(--additional-gray-blue);
}

.cardCategoriesItem {
  position: relative;
  background-color: var(--neutral-light-gray);
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 200px;
  overflow: hidden;
  border: none;
  pointer-events: none;
}

.cardCategoriesItem :global(.card-title) {
  font-size: 25px !important;
}

.cardCategoriesItem :global(.card-footer a) {
  display: none;
}

.cardCategoriesItem:first-child {
  background: var(--neutral-light-gray) url('../../../src/img/il-1.svg') no-repeat 100% 60%;
}

.cardCategoriesItem :global(.card-body) {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: 32px 36px 0;
  z-index: 2;
  width: 100%;
}

.cardCategoriesItem :global(.card-title) {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 16px;
}

.cardCategoriesItem :global(.card-text) {
  margin-bottom: 32px;
  color: var(--neutral-gray-blue);
  /*max-width: 300px;*/
}

.cardCategoriesItem :global(.card-footer) {
  position: relative;
  padding: 0 36px 28px;
  background-color: transparent;
  border-top: none;
  z-index: 2;
}

.cardCategoriesItem :global(.linkBtn) {
  display: inline-flex;
}

.cardCategoriesItem img {
  position: absolute;
  right: 5px;
  top: 15px;
  height: 240px;
  width: auto;
  z-index: 1;
}

.cardCategoriesItem {
  /*max-width: calc(33.3% - 12px);*/
  width: 100%;
  margin-bottom: 20px;
}

.cardCategoriesItem:nth-child(1),
.cardCategoriesItem:nth-child(2) {
  /*max-width: calc(50% - 12px);*/
  width: 100%;
}

@media (max-width: 1199px) {
  .cardCategoriesItem :global(.card-title) {
    font-size: 30px;
    line-height: 39px;
  }

  .cardCategoriesItem :global(.card-text) {
    margin-bottom: 0;
  }

  .cardCategoriesItem :global(.card-body) {
    padding: 10px;
  }

  .offersCardWrapper :global(.card) {
    max-width: 30%;
    margin: 10px;
  }
}

@media (max-width: 991px) {
  .cardCategoriesItem :global(.card-title) {
    font-size: 24px;
    line-height: 39px;
  }

  .cardCategoriesContainer {
    display: none;
  }

  .cardCategoriesItem,
  .cardCategoriesItem:nth-child(1),
  .cardCategoriesItem:nth-child(2) {
    max-width: none;
    margin-bottom: 12px;
  }

  .cardCategoriesContainer {
    padding: 30px 0 28px;
  }

  .vipCardWrapper {
    margin-bottom: 20px;
  }

  .buttonWrapper {
    text-align: center;
    margin-bottom: 20px;
  }

  .loginBtn {
    width: auto;
  }
  .cardCategoriesItem {
    width: 70% !important;
    margin: 0 auto 20px auto;
  }

  .offersCardWrapper :global(.card) {
    max-width: unset;
    margin: 10px;
    pointer-events: auto;
  }

  .offersCardWrapper div div {
    justify-content: space-around;
  }

  .offersCardWrapper :global(.show) {
    pointer-events: none;
  }

  .offersCardContainer {
    background-color: unset;
  }
}

@media (max-width: 600px) {
  ul.tcsNumberList li {
    position: relative;
    /*display: flex;*/
    /*align-items: center;*/
    color: var(--text-color);
    padding-left: 20px;
  }

  ul.tcsNumberList {
    padding-left: 0;
  }
}
