.container {
  padding: 40px 0 180px 0;
  overflow: hidden;
}

.loaderWrapper {
  height: 150px;
}

.bestScopeContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.bestScopeContainer :global(.jumbotron) {
  margin-right: 0;
}

.scoreText {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-left: 10px;
}

.scoreCategory {
  font-family: 'Ubuntu', sans-serif;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  color: var(--neutral-dark-blue);
}

.backButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

@media (max-width: 991px) {
  .container {
    padding: 40px 0 40px 0;
  }

  .scoreContainer {
    display: flex;
    justify-content: center;
    margin: 25px 0;
  }

  .scoreContainer :global(.jumbotron) {
    margin: 0;
  }
}

@media (max-width: 550px) {
  .container :global(table) {
    min-width: 600px;
  }
}
