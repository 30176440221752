.recoveryModal :global(.modal-body) {
  padding: 0 20px;
}

.recoveryModal :global(.modal-header) {
  padding: 20px;
}

.recoveryModal :global(.modal-body) {
  padding: 0 20px;
}

.recoveryModal :global(.modal-body) p {
  color: var(--not-changine-black);
}

.recoveryModal :global(.modal-title) {
  font-family: 'museo-slab', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--not-changine-black);
  margin-top: 0;
}

.recoveryModal :global(.form-group) {
  margin-bottom: 35px;
}

.linkBtn {
  font-family: 'museo-slab', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--button-default-focused);
  border: none;
  transition: all ease 0.3s;
  padding: 6px 24px;
}

.recoveryModal :global .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
