.sectionVipContent {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
}

.sectionVipItem {
  max-width: 50%;
  width: 100%;
}

.sectionVipItem :global .h3 {
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
}

.sectionVipItem:last-child {
  max-width: 45%;
}

.storeBtnList {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 72px;
}

.storeBtnItem {
  max-width: 164px;
  width: 100%;
  height: 48px;
  background-color: var(--blackColor);
  border-radius: 4px;
}

.storeBtnItem:first-child {
  margin-right: 12px;
}

.vipText {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
}

@media (max-width: 1199px) {
  .storeBtnList {
    justify-content: center;
  }
  .sectionVipItem .pageText {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .sectionVipContent {
    flex-wrap: wrap;
  }

  .sectionVipItem,
  .sectionVipItem:last-child {
    max-width: none;
    width: 100%;
    margin-bottom: 25px;
  }

  .storeBtnList {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .installAppsContainer {
    padding-top: 25px;
  }

  .installAppsContainer .storeBtnList {
    justify-content: space-between;
  }

  .installAppsContainer .storeBtnList a {
    max-width: 200px;
  }
}

@media (max-width: 600px) {
  .installAppsContainer .storeBtnList {
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
  }

  .installAppsContainer .storeBtnList a {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
  }
}
