.instructorInfo {
  background-color: var(--lavender-grey);
  border-radius: 10px;
  margin: 10% 0;
  width: 100%;
}

.instructorInfo:after {
  position: absolute;
  content: '';
  top: 0;
  left: 25%;
  width: 100%;
  height: 100%;
  max-width: 650px;
  background: url('../../../src/img/il-2.svg') no-repeat 50% 50%;
  background-size: contain;
}

.instructorContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 40px 40px 20px;
}

.instructorSchoolInfo {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.instructorContact {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 40px;
  border-top: 1px solid var(--additional-gray-blue);
}

.instructorContact:last-child,
.instructorContact:nth-child(3) {
  display: none;
}

.contactItem {
  max-width: calc(50% - 10px);
  width: 100%;
}

.userName {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 22px;
  color: var(--neutral-dark-blue);
}

.userInfo {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.instructorLabel {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
}

.instructorValue {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.instructorText {
  margin-top: 65px;
}

.imgContainer {
  width: 100%;
  max-width: calc(50% - 10px);
  transition: 0.5s all;
}

.imgContainer :global(img) {
  height: 100%;
  width: auto;
  /*width: 100%;*/
  /*height: auto;*/
}

:global(.dark-theme) .imgContainer :global img {
  height: 776px;
}

@media (max-width: 1200px) {
  .imgContainer :global(img) {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1450px) {
  .imgContainer :global(img) {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 991px) {
  .imgContainer {
    display: none;
  }

  .instructorInfo {
    margin-bottom: 0;
  }

  .instructorInfo:after {
    content: none;
  }

  .instructorContent,
  .instructorContact {
    padding: 20px;
  }

  .userName {
    font-size: 18px;
    line-height: 24px;
  }

  .userInfo {
    font-size: 14px;
    line-height: 22px;
  }

  .instructorValue {
    font-size: 18px;
    line-height: 22px;
  }

  .instructorContact:last-child,
  .instructorContact:nth-child(3) {
    display: flex;
  }

  .instructorContact:nth-child(2) {
    display: none;
  }
}
