.root {
  position: relative;
  margin-right: 17px;
  color: var(--theme-primary);
  padding-left: 30px;
  margin-bottom: 16px;
  border: none;
  background: var(--whiteColor);
  text-align: inherit;
  min-height: 75px;
}

.root:before {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -6px;
  width: 14px;
  height: 14px;
  font-family: 'icomoon', 'serif';
  font-size: 12px;
  transition: all ease 0.3s;
  line-height: 1;
}

.open.root:before {
  transform: rotate(90deg);
}

.root:hover {
  cursor: pointer;
}

.faqItem {
  margin-bottom: 21px;
  width: 100%;
  overflow: hidden;
}

.faqHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-bottom: 7px;
}

.faqText {
  display: block;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  padding-left: 30px;
  text-transform: none;
}

.faqItem :global(.collapse:not(.show)) {
  display: inherit;
  height: 0;
  transition: all ease 0.4s;
}

.faqItem :global(.collapse.show) {
  height: auto;
}

@media (max-width: 991px) {
  .questionsByTopicList {
    column-width: 320px;
  }

  .root {
    min-height: unset;
  }
}
