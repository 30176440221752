h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'museo-slab', sans-serif;
  font-weight: 700;
}

h1,
.h1 {
  font-size: 38px;
  line-height: 60px;
}

h2,
.h2 {
  font-size: 36px;
  line-height: 44px;
}

h3,
.h3 {
  font-size: 28px;
  line-height: 42px;
}

h4,
.h4 {
  font-size: 26px;
  line-height: 36px;
}

h5,
.h5 {
  font-size: 24px;
  line-height: 36px;
}

h6,
.h6 {
  font-size: 20px;
  line-height: 30px;
}

.h7 {
  font-family: 'hind', sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.h8 {
  font-family: 'hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

p {
  font-family: 'hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
}

.p3 {
  font-family: 'hind', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--blackColor);
}

@media (max-width: 991px) {
  h3,
  .h3 {
    font-size: 24px;
    line-height: 36px;
  }
}

button,
button:hover {
  border-color: transparent;
}
